var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white shadow-sm rounded-lg p-4"},[_c('el-table',{staticClass:"w-100",attrs:{"data":this.tableData.filter(
        (data) =>
          !_vm.search ||
          data.id
            .toString()
            .toLowerCase()
            .includes(_vm.search.toLowerCase()) ||
          data.name.toLowerCase().includes(_vm.search.toLowerCase())
      ),"default-sort":{ prop: 'id', order: 'ascending' }}},[_c('el-table-column',{attrs:{"prop":"id","sortable":"","label":"Id"}}),_c('el-table-column',{attrs:{"prop":"name","sortable":"","label":"Name"}}),(this.$store.getters.discriminator === 'administrator')?_c('el-table-column',{attrs:{"label":"Operations"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-input',{key:scope.row,staticClass:"p-0",attrs:{"placeholder":"Search..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]}},{key:"default",fn:function(scope){return [_c('el-button-group',[_c('el-button',{attrs:{"size":"mini","icon":"el-icon-edit"},on:{"click":function($event){return _vm.update(scope.row, scope.$index)}}}),_c('el-button',{attrs:{"size":"mini","icon":"el-icon-delete"},on:{"click":function($event){return _vm.remove(scope.row, scope.$index)}}})],1)]}}],null,false,4034925705)}):_vm._e()],1),_c('el-button',{staticClass:"fixed-bottom new-register",attrs:{"type":"success"},on:{"click":_vm.add}},[_vm._v("Add New Register")]),_c('el-drawer',{attrs:{"title":"Create New Title","visible":_vm.component.drawer,"direction":"rtl","size":"500px"},on:{"update:visible":function($event){return _vm.$set(_vm.component, "drawer", $event)}}},[_c('div',{staticClass:"pt-3 pl-5 pr-5 pb-5"},[_c('component-state-sales-tax',{key:_vm.component.render,attrs:{"data":_vm.component.data},on:{"refresh":function($event){return _vm.load($event)}}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }