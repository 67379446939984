<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <el-table
      :data="
        this.tableData.filter(
          (data) =>
            !search ||
            data.id
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            data.name.toLowerCase().includes(search.toLowerCase())
        )
      "
      :default-sort="{ prop: 'id', order: 'ascending' }"
      class="w-100"
    >
      <el-table-column prop="id" sortable label="Id"></el-table-column>
      <el-table-column prop="name" sortable label="Name"></el-table-column>
      <el-table-column
        label="Operations"
        v-if="this.$store.getters.discriminator === 'administrator'"
      >
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="search"
            placeholder="Search..."
            class="p-0"
            :key="scope.row"
          />
        </template>
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="update(scope.row, scope.$index)"
            ></el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              @click="remove(scope.row, scope.$index)"
            ></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-button class="fixed-bottom new-register" type="success" @click="add"
      >Add New Register</el-button
    >

    <el-drawer
      title="Create New Title"
      :visible.sync="component.drawer"
      direction="rtl"
      size="500px"
    >
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-state-sales-tax
          v-on:refresh="load($event)"
          :data="component.data"
          :key="component.render"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import stateSalesTax from "@/services/api/stateSalesTax";

import ComponentStateSalesTax from "@/components/admin/StateSalesTax";

export default {
  components: {
    ComponentStateSalesTax,
  },
  data() {
    return {
      tableData: [],
      search: "",
      component: {
        data: null,
        drawer: false,
        render: 0,
      },
      index: null,
    };
  },
  mounted() {
    stateSalesTax.get().then((response) => {
      this.tableData = response;
    });
  },
  methods: {
    load(event) {
      this.component.drawer = false;
      if (event.update) {
        this.tableData.splice(this.index, 1, event.data);
      } else {
        this.tableData.unshift(event.data);
      }
    },
    add() {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = null;
    },
    update(row, index) {
      this.index = index;
      //
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = row;
    },
    remove(row, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#67C23A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          stateSalesTax
            .delete(row)
            .then((response) => {
              this.tableData.splice(index, 1);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error",
              });
            });
        }
      });
    },
  },
};
</script>
