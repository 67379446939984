import axios from 'axios'

export default {
    async get() {
        try {
            const response = await axios.get('/saletax/states');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/saletax/state', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/saletax/state/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async delete(data) {
        try {
            const response = await axios.put(`/saletax/state/delete/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
